/* eslint-disable react/no-danger */
import ReactDOM from 'react-dom';
import dompurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useLocalStorage } from '../../src/hooks/useLocalStorage';

const CookieBanner = () => {
  const { set, get } = useLocalStorage();
  const { t } = useTranslation();
  const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>();
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);
  const sanitizer = dompurify.sanitize;
  useEffect(() => {
    if (get) {
      setPortalRoot(document.getElementById('portal-root'));
      setIsCookieAccepted(get('cookie_accepted'));
    }
  }, [get, setIsCookieAccepted, setPortalRoot]);

  const acceptCookie = () => {
    setIsCookieAccepted(true);
    set('cookie_accepted', true);
  };

  return !isCookieAccepted && portalRoot
    ? ReactDOM.createPortal(
        <div className="cookie-banner">
          <div dangerouslySetInnerHTML={{ __html: sanitizer(t('cookie_banner')) }} />
          <button className="btn btn-primary" type="button" onClick={acceptCookie}>
            {t('cookie_button')}
          </button>
        </div>,
        portalRoot
      )
    : null;
};

export default CookieBanner;
